* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Jost", sans-serif !important;
}

.navbar {
  padding: 0px !important;
}

.side-nav {
  position: fixed !important;
}

.bar {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px;
}

.b-bar-contain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 20px;
}

.product-search-new-tab {
  margin-top: 25px !important;
}

/* B-BAR-CONTENT */

.b-bar-content {
  width: 175px;
  color: white;
  /* border:2px solid red; */
  font-size: 16px;
  text-decoration: none !important;
  display: flex;
  padding: 6px;
  gap: 5px;
}

.nav-content {
  text-decoration: none !important;
}

/* B-BAR-FONT */

.b-bar-font {
  width: 30%;
  display: flex;
  justify-content: end;
  font-size: 1.2rem;
}

#active {
  background-color: #fff !important;
  color: #0d354f !important;
  border-radius: 5px !important;
}

/* B-BAR-FONT-CONTAINER */

.b-bar-font-content {
  width: 75%;
  display: flex;
  justify-content: start;
}

/* LOGO */

.logo {
  width: 150px;
  height: 100px;
  border-radius: 10px;
}

/* BAR */

.bar {
  width: 220px !important;
  height: 100vh;
  border-radius: 0px 20px 20px 0px;
  background-color: #0d354f;
  overflow-y: auto;
}

.yesterday {
  font-size: 10px !important;
  padding-left: 6px;
}

/* HOMEPAGE */

.home-container {
  display: flex;
  flex-direction: column;
  /* margin-left: 190*/
  gap: 20px;
}

.notify-font {
  width: 87%;
  padding: 20px !important;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  z-index: 80 !important;
  background-color: white;
  box-shadow: 0px 5px 5px lightgray;
}

.notify {
  width: 30px;
  height: 30px;
  background-color: #0d354f;
  border-radius: 50%;
  color: white !important;
  font-size: 10px;
  margin-right: 50px;
}

.bg-body-tertiary {
  background-color: transparent !important;
}

.new {
  color: white;
  background-color: #0d354f;
  border: none;
  padding: 5px 50px;
  border-radius: 5px;
  margin-right: 50px;
}

.notify-clear {
  color: #0d354f;
  font-size: 0.8rem;
}

.notify-txt {
  color: #0d354f;
  font-size: 0.8rem;
}

/* TODAY */

.today-count {
  padding: 12px 10px;
}

.today-count-card {
  width: 96.5% !important;
}

.today-order-home {
  width: 96.5% !important;
  border-radius: 10px !important;
  font-weight: 900 !important;
}
.today-order-table {
  width: 100% !important;
  /* border-radius: 10px !important; */
  font-weight: 900 !important;
}
.box {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  padding: 10px;
}

.product {
  height: 100vh !important;
}

:where(
    .css-dev-only-do-not-override-p8b6i
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select
  > .ant-upload,
:where(
    .css-dev-only-do-not-override-p8b6i
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select
  > .ant-upload {
  width: 400px !important;
  border: 2px solid lightblue;
  background-color: transparent;
  border: none !important;
}

.product-combine {
  display: flex;
  gap: 100px;
}

.product-textarea {
  width: 475px !important;
  height: 150px !important;
}
.dicount-combine {
  display: flex;
  justify-content: space-between;
}

.discount-meat {
  margin-top: 20px;
  justify-content: space-between;
}

div > h6 {
  color: #0d354f;
}

.discount-meatcombine {
  width: 166px;
  justify-content: space-between;
}

.discount-meat-text {
  font-size: 0.8rem;
}
:where(
    .css-dev-only-do-not-override-p8b6i
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
:where(
    .css-dev-only-do-not-override-p8b6i
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 100% !important;
}
:where(
    .css-dev-only-do-not-override-p8b6i
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
:where(
    .css-dev-only-do-not-override-p8b6i
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-p8b6i).ant-input-outlined:hover,
:where(.css-dev-only-do-not-override-p8b6i).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector:hover {
  border-color: #0d354f !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  margin: 0px 0px !important;
  padding: 0px !important;
  font-size: 14px !important;
}

.gap-tab {
  margin: 0px 20px !important;
}

.text-area {
  width: 100%;
  border-radius: 5px !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}
.css-pdct74-MuiTablePagination-selectLabel,
.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}
.today-count {
  width: 100%;
}

.css-1wnsr1i {
  width: 626px !important;
  /* height: 354px !important; */
  border: 2px solid white !important;
  border-radius: 10px !important;
}

.visible {
  justify-content: space-between;
}

.di-save {
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.di-save > button {
  border: none;
  background-color: #0d354f;
  color: white;
  padding: 5px 60px;
  border-radius: 5px;
}
:where(.css-dev-only-do-not-override-p8b6i).ant-switch.ant-switch-checked {
  background-color: #0d354f !important;
}

.perform-div {
  display: flex !important;
  flex-direction: column !important;
  gap: 14px !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-progress .ant-progress-outer {
  flex-direction: column !important;
  gap: 3px !important;
  align-items: start !important;
}

/* :where(.css-dev-only-do-not-override-p8b6i).ant-progress
  .ant-progress-bg::after {
  background-color: red !important;
} */

:where(.css-dev-only-do-not-override-p8b6i).ant-picker-outlined {
  border-color: #0d354f !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-input-outlined {
  border-color: #0d354f !important;
}
.order-combine {
  color: #0d354f;
  padding: 20px 30px;
}
.css-heg063-MuiTabs-flexContainer {
  margin-left: initial !important;
}

.select-pending {
  color: black !important;
  width: 190px !important;
}
::placeholder {
  color: black !important;
}

.product-search-1 {
  width: 190px !important;
  height: 35px !important;
}

.refund-icon {
  color: black;
  font-size: 30px;
  cursor: pointer;
}

.refund {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.refund-button {
  color: white;
  background-color: #0d354f;
  border: none;
  padding: 5px 50px;
  border-radius: 5px;
}

.orderdetail {
  margin-top: 50px !important;
  padding: 0px 38px;
}

.banner-combine {
  padding: 20px 30px;
}

.banner-icon {
  font-size: 20px;
  cursor: pointer;
}

.table-c {
  width: 96.5% !important;
  border-radius: 5px !important;
  margin-top: 30px !important;
  font-weight: bold !important;
}

.css-1ae1lpi-MuiPaper-root {
  box-shadow: none !important;
}

.table-contain {
  border-radius: 10px;
}

.banner-textarea {
  width: 350px !important;
  border-radius: 10px !important;
  border: 1px solid #0d354f !important;
}

.banner-textarea:hover {
  border-color: 1px solid #0d354f !important;
}

p,
:where(.css-dev-only-do-not-override-p8b6i).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-p8b6i).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}

h3 {
  font-size: 1.7rem !important;
  color: #0d354f !important;
  font-weight: bold;
}

h6 {
  font-size: 1.5rem !important;
  color: #0d354f !important;
  font-weight: bold;
}
.uploader {
  width: 350px !important;
}

label {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin-bottom: 7px;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #0d354f !important;
}

.h1 {
  color: #0d354f !important;
  font-size: 25px !important;
  font-weight: 600 !important;
}

.crt {
  width: 30px;
  padding: 3px 0px;
  color: white;
  background-color: limegreen;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}
.wrong {
  width: 30px;
  padding: 3px 0px;
  color: white;
  background-color: red;
  font-size: 1.2rem;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4.5px 10px !important;
}

.css-1aquho2-MuiTabs-indicator {
  bottom: 8px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #0d354f !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #000 !important;
}
.head {
  font-weight: 500;
}

.detail-txt {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 10px;
  color: black;
}

.detail-title {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: black !important;
}
.detail-txt-s {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.report-col {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}

.report-row {
  padding: 0px 10px !important;
  display: flex;
  width: 100% !important;
  margin-top: 20px !important;
}

.port-font {
  font-size: 2.5rem !important;
  color: #0d354f;
}

.css-19kzrtu {
  padding: 0 !important;
  padding-top: 0px !important;
}

.table-box {
  border: 1px solid lightgrey;
  box-shadow: 0px 0px 9px 1px lightgrey;
  border-radius: 5px;
}

.datepicker {
  width: 100%;
}

.total {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.totalcon {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.percent-bgdrop {
  background-color: #ff8a8a;
  border-radius: 10px;
  padding: 2px 5px;
}

.percent-bg {
  background-color: #deffce;
  border-radius: 10px;
  padding: 2px 5px;
}

.title {
  font-size: 25px;
  font-weight: 700;
}

.table-box2 {
  border: 1px solid #000;
  border-radius: 5px;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-p8b6i)[class^="ant-checkbox"]
  [class^="ant-checkbox"],
:where(.css-dev-only-do-not-override-p8b6i)[class*=" ant-checkbox"]
  [class^="ant-checkbox"],
:where(.css-dev-only-do-not-override-p8b6i)[class^="ant-checkbox"]
  [class*=" ant-checkbox"],
:where(.css-dev-only-do-not-override-p8b6i)[class*=" ant-checkbox"]
  [class*=" ant-checkbox"] {
  background-color: #000 !important;
  color: white !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-pagination
  .ant-pagination-item-active {
  border-color: #0d354f !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-switch.ant-switch-small
  .ant-switch-inner {
  padding-inline-start: 15px !important;
}

.card {
  width: 100% !important;
  height: 300px !important;
  border: none !important;
  margin-top: 5px !important;
}
.width {
  width: 100px !important;
  /* border:2px solid red; */
}
.card-1 {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: start !important;
  align-items: center !important;
  position: relative !important;
}

.card-2 {
  width: 180px !important;
  height: 180px !important;
  margin-top: 90px !important;
}
.circle {
  border: 1px solid #0d354f;
  padding: 25px 20px;
  margin-top: 20px;
  border-radius: 10px;
  width: 87%;
}

.donut-chart {
  position: absolute;
  left: 50% !important;
  bottom: 23% !important;
}

.circle-txt {
  font-size: 9px !important;
  padding: 2px;
}

.card-3 {
  width: 200px !important;
  display: flex;
  justify-content: space-evenly;
  /* gap:20px */
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: lowercase !important;
} */
.cl-1 {
  width: 10px;
  height: 10px;
  background-color: #0d3c61 !important;
}
.cl-2 {
  width: 10px;
  height: 10px;
  background-color: #1769aa !important;
}
.cl-3 {
  width: 10px;
  height: 10px;
  background-color: #a0d2fa !important;
}

.col-combine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.center-chicken {
  position: absolute;
  left: 22% !important;
  bottom: 27% !important;
}

.report-week {
  display: flex;
  justify-content: end;
  margin-right: 12px;
  align-items: center;
}

.navbar-overall {
  margin-left: 190px;
}

.weekly-report-contain {
  width: 99% !important;
  min-height: 600px;
  padding: 20px;
}

.graph-contain {
  border: 1px solid #0d354f;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.graph {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
}

.weekly-select {
  width: 150px !important;
}

.product-search-combine {
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.product-search {
  width: 190px !important;
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-badge {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-badge .ant-badge-count,
:where(.css-dev-only-do-not-override-1gwfwyx).ant-badge .ant-badge-dot,
:where(.css-dev-only-do-not-override-1gwfwyx).ant-badge
  .ant-scroll-number-custom-component {
  transform: translate(137%, 0%) !important;
}

.banner-icon-combine {
  display: flex;
}

.sales {
  width: 96.5% !important;
}

.row-banner {
  padding: 0px 65px !important;
}

.pending {
  width: 126px !important;
}

.pending-o {
  width: 90px !important;
}
.product-search-new {
  width: 400px !important;
  height: 35px !important;
}

.product-search-gram {
  width: 115px !important;
  height: 35px !important;
}
.banner-select {
  width: 350px !important;
  height: 35px !important;
  color: black !important;
}

.width-1 {
  width: 180px !important;
}
/* MEDIA QUERY'S FOR TAB VIEW*/

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .notify-font {
    width: 89%;
  }
  .bar {
    width: 100px !important;
  }
  .b-bar-contain {
    gap: 40px;
  }
  .b-bar-content {
    justify-content: start;
    padding: 0px;
  }
  .b-bar-font-content {
    display: none !important;
  }
  .b-bar-content:hover {
    background-color: transparent;
  }
  .b-bar-font {
    color: #fff !important;
    justify-content: end;
    font-size: 25px !important;
  }
  .navbar-overall {
    margin-left: 100px !important;
  }
  .logo {
    width: 85px;
    height: auto !important;
  }
  .overall {
    width: 100%;
  }

  .weekly-report-contain {
    height: 100vh;
  }

  .card {
    width: 100%;
  }

  .card-2 {
    width: 100px !important;
    height: 100px !important;
    margin-top: 130px !important;
  }
  .card-3 {
    width: 100px !important;
  }

  .donut-chart {
    /* left: 22% !important; */
    bottom: 10% !important;
  }

  .center-chicken {
    left: 12% !important;
    bottom: 15% !important;
  }
  .graph-contain {
    width: 100%;
  }
  .weekly-select {
    width: 150px !important;
  }

  .weekly-select-combine {
    flex-direction: column;
    gap: 10px !important;
  }

  .circle {
    width: 100% !important;
  }

  .banner-select-1 {
    width: 90% !important;
  }

  .new {
    margin-right: 10px !important;
  }

  .new-button {
    margin-left: 30px !important;
  }
  .product-search-combine {
    width: 100% !important;
  }

  .product-search {
    width: 140px !important;
  }

  .banner-icon-combine {
    display: flex;
  }
  .total {
    font-size: 12px !important;
  }

  .totalcon {
    font-size: 12px !important;
  }

  .percent-bg,
  .percent-bgdrop {
    font-size: 12px !important;
  }
  .model-banner {
    width: 650px !important;
  }
  .row-banner {
    padding: 10px !important;
  }
  .banner-select {
    width: 90% !important;
  }
  .banner-select-img {
    width: 245px !important;
  }
  .product-search-new {
    width: 230px !important;
  }

  .product-search-new-tab {
    width: 100% !important;
    margin-top: 22px !important;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 10px !important;
  }
  .product-combine {
    gap: 30px !important;
  }
  .product-search-new-1 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
  .product-search-gram {
    width: 100px !important;
  }
  .table-width {
    width: 100% !important;
  }
  .sales {
    width: 100% !important;
  }
  .product-search-1 {
    width: 140px !important;
    height: 37px !important;
  }
  .tabs {
    color: red !important;
    border: 2px solid red !important;
  }
  .but {
    padding: 5px 30px !important;
  }
  .down {
    margin-right: 7px !important;
  }
  .dd {
    padding: 10px !important;
  }
}

/* Login */

log-btn button {
  background-color: #ff518a !important;
  width: 60% !important;
  height: 45px !important;
  border-radius: 50px !important;
}
.log-btn p {
  font-size: 16px;
  font-weight: 400;
  color: #555555;
  padding-top: 10px;
}

.but {
  background-color: #0d354f !important;
  padding: 7px 85px !important;
  border-radius: 25.5px !important;
  color: white !important;
  border: none !important;
}

.forgetpass {
  color: gray !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.log-btn p a {
  font-size: 16px;
  font-weight: 400;
  color: #ff518a !important;
  padding-top: 10px;
  text-decoration: underline !important;
  cursor: pointer;
}
.login-box {
  width: 80%;
  background-color: #fff;
  padding: 30px;
  font-family: "Jost", sans-serif !important;
}

.topic {
  font-size: 30px;
  font-weight: 600 !important;
}

.sub-t {
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: "Jost", sans-serif !important;
}
.sub-t-1 {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: grey;
  font-family: "Jost", sans-serif !important;
}

.log-btn p a {
  color: #0d354f !important;
}
.log {
  color: grey;
  font-weight: 400 !important ;
  font-size: 12px !important;
}
@media (max-width: 768px) {
  .login-box {
    width: 100%;
  }
}
.login-bg {
  background-image: url("../public/assests/login-bg.png");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  background-color: rgb(168, 49, 49) !important;
}

:where(.css-dev-only-do-not-override-p8b6i).ant-upload-wrapper
  .ant-upload-list {
  display: none !important;
}

.max-img {
  width: 355px !important;
}
.max-img:hover {
  border: 1px solid #0d354f !important;
  color: #0d354f !important;
}

.banner-input-toggle {
  width: 355px;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #0d354f !important;
}
.add-btn {
  background-color: #0d354f !important;
  padding: 2px 30px;
}
.create-input {
  width: 100%;
  border: 1px solid #000;
  height: 40px;
}
.add-btn {
  background-color: #0d354f !important;
}
.invoicebtn {
  background-color: #0d354f;
  color: #fff;
  border: 1px solid #0d354f;
  padding: 5px 20px;
  margin-bottom: 10px;
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  background-color: #0d354f !important;
  border-color: #0d354f !important;
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-pagination
  .ant-pagination-options-size-changer {
  display: none !important;
}
.filter-btn {
  color: #0d3c61 !important;
}
.product-margin{
 padding: 50px 40px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
  color: #0d354f !important;
}
